import { apiRequest } from './ApiService';

export const putPageSize = (body) => apiRequest().put(`/aw-api/user/setting`, { ...body });

export const getPageSize = (paramsData, pathVars) =>
    apiRequest().get('/aw-api/user/setting/page-list', {
        params: { ...paramsData, ...pathVars },
    });

export const getErrorMsg = (id) => apiRequest().get('/aw-api/error-code/' + id);

export const subscribeFCM = (body) => apiRequest().post('/aw-api/notification/fcm', body);

export const unsubscribeFCM = (body) =>
    apiRequest().post('/aw-api/notification/fcm/unsubscribe', body);

export const delInvoiceDiscount = (path, id) =>
    apiRequest().delete(`/aw-api/${path}/invoice/discount/${id}`);

export const delInvoice = (path, id) => apiRequest().delete(`/aw-api/${path}/invoice/${id}`);

export const getVer = (paramsData, pathVars) =>
    apiRequest().get('/aw-api/user/setting/page-list', {
        params: { ...paramsData, ...pathVars },
    });

export const putVer = (body) => apiRequest().put(`/aw-api/user/setting`, { ...body });
