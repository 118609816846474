import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import LoginPage from '../pages/Login/LoginPage';
import PublicLayout from '../layouts/index';
import Loading from '../pages/Login/Loading';
import CheckNavigationType from '../components/CheckNavigationType';
const Home = React.lazy(() => import('../pages/Home'));
const Dashbaord = React.lazy(() => import('../pages/Dashbaord'));
const NewCylinder = React.lazy(() => import('../pages/Cylinder/NewCylinder'));
const CylinderDetailed = React.lazy(() => import('../pages/Cylinder/CylinderDetailed'));
const NewCusForOrders = React.lazy(() => import('../pages/Orders/NewOrders/NewCusForOrders'));
const CylinderSites = React.lazy(() => import('../pages/Cylinder/CylinderSites'));
const CylinderSitesDeatiled = React.lazy(() =>
    import('../pages/Cylinder/CylinderSites/CylinderSitesDeatiled'),
);
const NewSites = React.lazy(() => import('../pages/Cylinder/CylinderSites/NewSites'));
const CustomerList = React.lazy(() => import('../pages/Customer/AllCustomer'));
const NewCustomer = React.lazy(() => import('../pages/Customer/NewCustomer'));
const AllOrders = React.lazy(() => import('../pages/Orders/AllOrders'));
const NewOrders = React.lazy(() => import('../pages/Orders/NewOrders'));
const GasPrice = React.lazy(() => import('../pages/Orders/GasPrice'));
const LowGas = React.lazy(() => import('../pages/Orders/LowGas'));
const OrderSearch = React.lazy(() => import('../pages/Orders/OrderSearch'));
const AllDevice = React.lazy(() => import('../pages/Device/AllDevice'));
const AllAcc = React.lazy(() => import('../pages/Account/AllAcc'));
const GroupList = React.lazy(() => import('../pages/Account/GroupList'));
const NewGroup = React.lazy(() => import('../pages/Account/GroupList/NewGroup'));
const NewAcc = React.lazy(() => import('../pages/Account/NewAcc'));
const AllCommodity = React.lazy(() => import('../pages/Commodity/AllCommodity'));
const CommodityDetailed = React.lazy(() => import('../pages/Commodity/CommodityDetailed'));
const NewCommodity = React.lazy(() => import('../pages/Commodity/NewCommodity'));
const Adv = React.lazy(() => import('../pages/Adv'));
const System = React.lazy(() => import('../pages/System'));
const AccDetailed = React.lazy(() => import('../pages/Account/AccDetailed'));
const CustomerDetailed = React.lazy(() => import('../pages/Customer/CustomerDetailed'));
const DeviceDetailed = React.lazy(() => import('../pages/Device/DeviceDetailed'));
const OrdersDetailed = React.lazy(() => import('../pages/Orders/OrdersDetailed'));
const LowGasDetailed = React.lazy(() => import('../pages/Orders/LowGas/LowGasDetailed'));
const GroupDetailed = React.lazy(() => import('../pages/Account/GroupList/GroupDetailed'));
const NotFound = React.lazy(() => import('../pages/NotFound'));
const AllCylinder = React.lazy(() => import('../pages/Cylinder/AllCylinder'));
const CylinderPrice = React.lazy(() => import('../pages/Cylinder/CylinderPrice'));
const AllWorkOrder = React.lazy(() => import('../pages/WorkOrder/AllWorkOrder'));
const WorkOrderDetailed = React.lazy(() => import('../pages/WorkOrder/WorkOrderDetailed'));
const NewWorkOrder = React.lazy(() => import('../pages/WorkOrder/NewWorkOrder'));
const AllWorkOrderBill = React.lazy(() =>
    import('../pages/WorkOrder/WorkOrderBill/AllWorkOrderBill'),
);
const WorkOrderBillDetail = React.lazy(() =>
    import('../pages/WorkOrder/WorkOrderBill/WorkOrderBillDetail'),
);
const AllCourier = React.lazy(() => import('../pages/Account/Courier/AllCourier'));
const MonthlyCusList = React.lazy(() => import('../pages/Customer/MonthlyCustomer/MonthlyCusList'));
const MonthlyCusDetailed = React.lazy(() =>
    import('../pages/Customer/MonthlyCustomer/MonthlyCusDetailed'),
);
const OperatingReport = React.lazy(() => import('../pages/Finance/OperatingReport'));
const MonthOperatingReport = React.lazy(() => import('../pages/Finance/MonthOperatingReport'));
const CourierReport = React.lazy(() => import('../pages/Finance/CourierReport'));
const NormalDeviceList = React.lazy(() =>
    import('../pages/Customer/NormalDevice/NormalDeviceList'),
);
const NormalDeviceDetailed = React.lazy(() =>
    import('../pages/Customer/NormalDevice/NormalDeviceDetailed'),
);
const BillOfSaleWorkList = React.lazy(() =>
    import('../pages/Orders/BillOfSaleWork/BillOfSaleWorkList'),
);
const BillOfSaleDetailed = React.lazy(() =>
    import('../pages/Orders/BillOfSaleWork/BillOfSaleDetailed'),
);
const MixedExpense = React.lazy(() => import('../pages/Finance/MixedExpense'));
const QuickOrders = React.lazy(() => import('../pages/QuickOrders'));
const UserSecurityList = React.lazy(() => import('../pages/UserSecurity/UserSecurityList'));
const UserSecurityDetail = React.lazy(() => import('../pages/UserSecurity/UserSecurityDetail'));
const InvoiceList = React.lazy(() => import('../pages/Invoice/AllInvoice'));
const InvoiceDetailed = React.lazy(() => import('../pages/Invoice/InvoiceDetailed'));

const routes = [
    {
        path: '/',
        element: <Navigate to='/loading' />,
    },
    {
        path: '/loading',
        element: <Loading />,
    },
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        element: <PublicLayout />,
        children: [
            {
                path: '/Home',
                element: <Home />,
                breadcrumbName: '首頁',
            },
            {
                path: '/Dashbaord',
                element: <Dashbaord />,
                breadcrumbName: '儀表板',
            },
            {
                path: '/customer/CustomerList',
                element: <CustomerList />,
                breadcrumbName: '客戶列表',
            },
            {
                path: '/:ComeFrom1/:ComeFrom2/CustomerDetailed/:userId',
                element: <CustomerDetailed />,
                breadcrumbName: '客戶詳細資料',
            },
            {
                path: '/customer/NewCustomer',
                element: <NewCustomer />,
            },
            {
                path: '/customer/MonthlyCusList',
                element: <MonthlyCusList />,
            },
            {
                path: '/customer/MonthlyCusList/MonthlyCusDetailed/:cis_id',
                element: <MonthlyCusDetailed />,
            },
            {
                path: '/customer/NormalDeviceList',
                element: <NormalDeviceList />,
            },
            {
                path: '/customer/NormalDeviceList/NormalDeviceDetailed/:cis_id',
                element: <NormalDeviceDetailed />,
            },
            {
                path: '/orders/AllOders',
                element: <AllOrders />,
                breadcrumbName: '訂單列表',
            },
            {
                path: '/:ComeFrom1/:ComeFrom2/OrdersDetailed/:order_id',
                element: <OrdersDetailed />,
                breadcrumbName: '訂單資料',
            },
            {
                path: '/orders/NewOrders',
                element: <NewOrders />,
            },
            {
                path: '/orders/NewOrders/NewCustomer',
                element: <NewCusForOrders />,
            },
            {
                path: '/orders/LowGas',
                element: <LowGas />,
            },
            {
                path: '/orders/LowGas/LowGasDetailed/:alert_id',
                element: <LowGasDetailed />,
            },
            {
                path: '/orders/GasPrice',
                element: <GasPrice />,
            },
            {
                path: '/orders/OrderSearch',
                element: <OrderSearch />,
            },
            {
                path: '/orders/BillOfSaleWorkList',
                element: <BillOfSaleWorkList />,
            },
            {
                path: '/orders/BillOfSaleWorkList/BillOfSaleDetailed/:bill_id',
                element: <BillOfSaleDetailed />,
            },
            {
                path: '/device/AllDevice',
                element: <AllDevice />,
            },
            {
                path: '/:ComeFrom1/:ComeFrom2/DeviceDetailed/:device_id',
                element: <DeviceDetailed />,
            },
            {
                path: '/account/AllAcc',
                element: <AllAcc />,
            },
            {
                path: '/:ComeFrom1/:ComeFrom2/AccDetailed/:acc_id',
                element: <AccDetailed />,
            },
            {
                path: '/account/Group/GroupList',
                element: <GroupList />,
            },
            {
                path: '/account/:ComeFrom1/:ComeFrom2/GroupDetailed/:group_id',
                element: <GroupDetailed />,
            },
            {
                path: '/account/Group/NewGroup',
                element: <NewGroup />,
            },
            {
                path: '/account/NewAcc',
                element: <NewAcc />,
            },
            {
                path: '/account/AllCourier',
                element: <AllCourier />,
            },
            {
                path: '/cylinder/AllCylinder',
                element: <AllCylinder />,
            },
            {
                path: '/:ComeFrom1/:ComeFrom2/CylinderDetailed/:cylinder_id',
                element: <CylinderDetailed />,
            },
            {
                path: '/cylinder/NewCylinder',
                element: <NewCylinder />,
            },
            {
                path: '/cylinder/CylinderSites',
                element: <CylinderSites />,
            },
            {
                path: '/cylinder/CylinderSites/CylinderSitesDeatiled/:factory_id',
                element: <CylinderSitesDeatiled />,
            },
            {
                path: '/cylinder/CylinderSites/NewCylinderSites',
                element: <NewSites />,
            },
            {
                path: '/cylinder/CylinderPrice',
                element: <CylinderPrice />,
            },
            {
                path: '/commodity/AllCommodity',
                element: <AllCommodity />,
            },
            {
                path: '/:ComeFrom1/:ComeFrom2/CommodityDetailed/:commodity_id',
                element: <CommodityDetailed />,
            },
            {
                path: '/commodity/NewCommodity',
                element: <NewCommodity />,
            },
            {
                path: '/work_order/AllWorkOrder',
                element: <AllWorkOrder />,
            },
            {
                path: '/:ComeFrom1/:ComeFrom2/WorkOrderDetailed/:work_order_id',
                element: <WorkOrderDetailed />,
            },
            {
                path: '/work_order/NewWorkOrder',
                element: <NewWorkOrder />,
            },
            {
                path: '/work_order/AllWorkOrderBill',
                element: <AllWorkOrderBill />,
            },
            {
                path: '/work_order/AllWorkOrderBill/WorkOrderBillDetail/:work_order_bill_id',
                element: <WorkOrderBillDetail />,
            },
            {
                path: '/finance/MonthOperatingReport',
                element: <MonthOperatingReport />,
            },
            {
                path: '/finance/OperatingReport',
                element: <OperatingReport />,
            },
            {
                path: '/finance/CourierReport',
                element: <CourierReport />,
            },
            {
                path: '/finance/MixedExpense',
                element: <MixedExpense />,
            },
            {
                path: '/invoice/InvoiceList',
                element: <InvoiceList />,
            },
            {
                path: '/invoice/InvoiceList/InvoiceDetailed/:invoice_id',
                element: <InvoiceDetailed />,
            },
            {
                path: '/userSecurity',
                element: <UserSecurityList />,
            },
            {
                path: '/userSecurity/userSecurityDetail/:address_id/:cis_id',
                element: <UserSecurityDetail />,
            },
            {
                path: '/adv',
                element: <Adv />,
            },
            {
                path: '/system',
                element: <System />,
            },
            {
                path: '/quick-orders',
                element: <QuickOrders />,
            },
            {
                path: '/404',
                element: <NotFound />,
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
];

const Router = () => {
    const router = useRoutes(routes);
    CheckNavigationType();
    return router;
};

export default Router;
